/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from "react";

import styled from "@emotion/styled";
import { Page } from "@/components/Page";
import Link from "next/link";

import { getContextForApp } from "@fllite/shared";
import { Circle } from "@fllite/ui/components";
import { Icon, IconKeys } from "@fllite/ui/components";
import { Layout as CommonLayout } from "@fllite/ui/components";
import { Logo } from "@fllite/ui/components";
import { H4, Paragraph } from "@fllite/ui/components";

interface BenefitIconProps {
  order?: number;
}

const PageWrapper = styled("div")`
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    overflow: hidden;
  }
`;

const Container = styled("div")`
  position: relative;

  margin: auto;
  width: 100%;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    display: flex;
    height: 100vh;
    min-height: 900px;
  }
`;

const BenefitsContainer = styled("div")`
  display: none;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    position: absolute;
    width: ${({ theme }) =>
      `calc(50% + ${(theme as any).dimensions.formWidthPortionXl})`};
    right: ${({ theme }) =>
      `calc(50% - ${(theme as any).dimensions.formWidthPortionXl})`};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: url("/assets/header_bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 50%;
    background-color: #022366;
    padding-top: 148px;
    height: 100%;
  }
`;

const RightContainer = styled("div")`
  width: 100%;
  height: 100%;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    display: block;
    position: absolute;
    left: ${({ theme }) =>
      `calc(50% + ${(theme as any).dimensions.formWidthPortionXl})`};
    width: ${({ theme }) =>
      `calc(50% - ${(theme as any).dimensions.formWidthPortionXl})`};
    height: 100%;
    background-color: #ffffff;
    padding-top: 92px;
  }
`;

const ModalContainer = styled("div")`
  width: 100%;
  position: relative;
  padding: 0 15px;
`;

const Modal = styled("div")`
  width: 100%;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    position: absolute;
    background: #ffffff;
    border-radius: ${({ theme }) => (theme as any).radius.boxRadius};
    box-shadow: ${({ theme }) => (theme as any).shadow.popup};
    width: 500px;
    right: 15px;
  }

  @media (min-width: 1150px) {
    width: 672px;
  }
`;

const StyledCircle = styled(Circle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
`;

const CircleContainer = styled("div")`
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 10px;
`;

const BenefitsContent = styled("div")`
  width: 100%;
  padding: 0 15px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    max-width: ${({ theme }) =>
      `calc(${(theme as any).dimensions.signUpWidth} / 2 + ${(theme as any).dimensions.formWidthPortionXl})`};
  }
`;

const RightContainerContent = styled("div")`
  width: 100%;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    max-width: ${({ theme }) =>
      `calc(${(theme as any).dimensions.signUpWidth} / 2 - ${(theme as any).dimensions.formWidthPortionXl})`};
  }
`;

const StyledH4 = styled(H4)`
  margin-bottom: 20px;
`;

const BenefitContainer = styled("div")`
  display: flex;
  align-items: center;
  max-width: 450px;
`;

const BenefitIcon = styled(Icon)<BenefitIconProps>`
  width: ${({ order }) =>
    order === 1 ? "36px" : order === 2 ? "50px" : "60px"};
  position: relative;
  top: ${({ order }) => (order === 3 ? "1px" : order === 2 ? "2px" : "4px")};
  left: ${({ order }) => (order === 1 ? "-1px" : order === 2 ? "5px" : "11px")};

  .svg {
    ${({ order }) => (order === 1 ? "36px" : order === 2 ? "50px" : "60px")};
  }
`;

const Close = styled(Icon)`
  opacity: 0.8;
  transition: opacity ${({ theme }) => (theme as any).transition.baseTransition};
  display: inline-flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;

    ${({ theme }) => (theme as any).breakpoints.up("md")} {
      width: 32px;
      height: 32px;
    }
  }

  g {
    ${({ theme }) => (theme as any).breakpoints.down("md")} {
      stroke: #fff;
      stroke-width: 3px;
    }
  }

  :hover {
    opacity: 1;
  }

  ${({ theme }) => (theme as any).breakpoints.down("md")} {
    opacity: 1;
  }
`;

const Header = styled("div")`
  background: ${({ theme }) => (theme as any).backgrounds.primaryGradient};
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ theme }) => (theme as any).dimensions.signUpWidth};
  width: 100%;
  padding: 15px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    position: absolute;
    background: transparent;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    padding-top: 33px;
  }
`;

const Anchor = styled("a")`
  display: inline-flex;
  align-items: center;
`;

interface BenefitProps {
  icon: IconKeys;
  order: number;
  children: ReactNode;
}

const Benefit: FC<BenefitProps> = ({ icon, children, order }) => (
  <BenefitContainer>
    <CircleContainer>
      <BenefitIcon order={order} type={icon} />
      <StyledCircle radius={60} pulsing />
    </CircleContainer>
    <Paragraph>{children}</Paragraph>
  </BenefitContainer>
);

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { webUrl } = getContextForApp();

  return (
    <CommonLayout>
      <Page hasNavigation={false} hasFooter={false}>
        <PageWrapper>
          <Container>
            <Header>
              <Link href={webUrl!} passHref legacyBehavior>
                <Anchor>
                  <Logo />
                </Anchor>
              </Link>
              <Link href={webUrl!} passHref legacyBehavior>
                <Anchor>
                  <Close type="close-gray" />
                </Anchor>
              </Link>
            </Header>
            <BenefitsContainer>
              <BenefitsContent>
                <StyledH4 data-testid="h4-welcome">
                  We welcome you to our Fllite Community
                </StyledH4>
                <Benefit order={1} icon="affordable-white">
                  Seamless access to private aircraft
                </Benefit>
                <Benefit order={2} icon="sharing-white">
                  Share the cost with others and save up to 90%
                </Benefit>
                <Benefit order={3} icon="comfort-white">
                  Enjoy the journey
                </Benefit>
              </BenefitsContent>
            </BenefitsContainer>
            <RightContainer>
              <RightContainerContent>
                <ModalContainer>
                  <Modal>{children}</Modal>
                </ModalContainer>
              </RightContainerContent>
            </RightContainer>
          </Container>
        </PageWrapper>
      </Page>
    </CommonLayout>
  );
};
