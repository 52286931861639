import { useEffect } from "react";

import { Layout } from "@/components/PageAuthCommon/Layout";
import { Form } from "@/components/PageLogin/Form";
import Head from "next/head";
import { useRouter } from "next/router";

import { useAnalytics, useAnonymousUser } from "@fllite/ui/hooks";

const PageLogin = () => {
  const { track } = useAnalytics();
  const { value: anonymousUserId } = useAnonymousUser();
  const router = useRouter();

  useEffect(() => {
    track("login_view", {
      cookieID: anonymousUserId,
      page: "login_form",
    });
  }, []);

  useEffect(() => {
    router.prefetch("/");
    router.prefetch("/register");
  }, [router]);

  return (
    <Layout>
      <Head>
        <title>Fllite - Login</title>
      </Head>
      <Form />
    </Layout>
  );
};

export default PageLogin;
