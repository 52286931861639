/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from "react";

import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment } from "@mui/material";
import { GoogleButton } from "@/components/PageAuthCommon/GoogleButton";
import { SectionCaption } from "@/components/PageAuthCommon/SectionCaption";
import { Tab, Tabs } from "@/components/PageAuthCommon/Tabs";
import Link from "next/link";
import { useRouter } from "next/router";
import { FormContainer as RHForm, TextFieldElement } from "react-hook-form-mui";

import { useAuthentication } from "@fllite/ui/hooks";
import { Button } from "@fllite/ui/components";
import { Icon } from "@fllite/ui/components";
import { FullScreenLoader } from "@fllite/ui/components";
import { Text } from "@fllite/ui/components";
import { PasswordElement } from "@fllite/ui/mui-form-fields";

import { schema } from "./validationSchema";

const Container = styled("div")``;

const FormContainer = styled("div")`
  padding: 30px 0;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    padding: 30px 40px;
  }
`;

const BottomSection = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const SubmitButton = styled(Button)`
  min-width: 293px;
  display: block;
  margin-bottom: 25px;
  position: relative;
`;

const LinkText = styled(Text)`
  color: ${({ theme }) => (theme as any).colors.primary};
  transition: color ${({ theme }) => (theme as any).transition.baseTransition};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => (theme as any).colors.primaryDark};
  }
`;

const Row = styled("div")`
  position: relative;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin: 0 -15px;
    display: flex;
    align-items: flex-start;
  }
`;

const Col = styled("div")`
  margin-top: 25px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-top: 0;
    width: 100%;
    padding: 0 15px;
  }
`;

const InputContainer = styled("div")`
  margin: 20px 0;
  & input {
    // chrome autofill styles, we need use the most similar, because chrome autofill styles are not customizable easily
    font-family: sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
  }
`;

type FormValues = {
  username: string;
  password: string;
};

interface Props {
  afterLogin?: () => void;
  isRAF?: boolean;
}

export const Form: FC<Props> = ({ afterLogin, isRAF }) => {
  const { pathname } = useRouter();
  const { isLoadingSignIn, signIn } = useAuthentication();
  const [googleIsLoading, setGoogleIsLoading] = useState(false);
  const handleFormSubmit = async (values: FormValues) => {
    await signIn(values, afterLogin ? () => afterLogin() : () => {});
  };

  return (
    <Container>
      {googleIsLoading && <FullScreenLoader isTransparent />}
      <Tabs>
        <Link
          passHref
          href={isRAF ? "/request-a-flight" : "/register"}
          legacyBehavior
        >
          <Tab
            active={
              pathname === "/register" || pathname === "/request-a-flight"
            }
            data-testid="pageLoginFormRegisterTab"
          >
            Create an account
          </Tab>
        </Link>
        <Link
          passHref
          href={isRAF ? "/request-a-flight-login" : "/login"}
          legacyBehavior
        >
          <Tab
            active={
              pathname === "/login" || pathname === "/request-a-flight-login"
            }
            data-testid="pageLoginFormLoginTab"
          >
            Log in
          </Tab>
        </Link>
      </Tabs>
      <FormContainer data-testid="loginForm">
        <GoogleButton isRAF={isRAF} setGoogleIsLoading={setGoogleIsLoading} />

        <SectionCaption>or using email</SectionCaption>

        <RHForm onSuccess={handleFormSubmit} resolver={yupResolver(schema)}>
          <Row>
            <Col>
              <InputContainer>
                <TextFieldElement
                  name="username"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon type="at-sign-blue" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Your email address"
                  variant="standard"
                  fullWidth
                />
              </InputContainer>
            </Col>
          </Row>

          <Row>
            <Col>
              <InputContainer>
                <PasswordElement
                  name="password"
                  placeholder="Your password"
                  iconColor="primary"
                  variant="standard"
                  fullWidth
                />
              </InputContainer>
            </Col>
          </Row>
          <BottomSection>
            <SubmitButton
              secondary
              type="submit"
              isLoading={isLoadingSignIn || googleIsLoading}
            >
              Log in
            </SubmitButton>
            <Link href="/forgotten-password" passHref legacyBehavior>
              <LinkText data-testid="forgot-password" as="a" small>
                Forgot password?
              </LinkText>
            </Link>
          </BottomSection>
        </RHForm>
      </FormContainer>
    </Container>
  );
};
