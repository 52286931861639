import { FC, useMemo } from "react";

import styled from "@emotion/styled";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useRouter } from "next/router";
import { getContextForApp } from "@fllite/shared";
import {
  useAnalytics,
  useAnonymousUser,
  useAuthentication,
} from "@fllite/ui/hooks";
import { useFlashMessage } from "@fllite/ui/components";
import { useEstimations } from "@fllite/ui/hooks";
import { add, isAfter } from "@fllite/ui/utils";
import { parseValuesForEstimation } from "@fllite/ui/utils";
import { getRafFormValues } from "@fllite/ui/utils";

const StyledButton = styled("div")`
  margin: auto;
  position: relative;

  iframe {
    margin: auto !important;
  }
`;

interface Props {
  isRAF?: boolean;
  setGoogleIsLoading?: (isLoading: boolean) => void;
}

export const GoogleButton: FC<Props> = ({ isRAF, setGoogleIsLoading }) => {
  const { signInGoogle } = useAuthentication();
  const { apiClientId, apiClientSecret } = getContextForApp();
  const { pushFlashMessage, FlashMessageTypes } = useFlashMessage();
  const rafFormValues = useMemo(() => getRafFormValues(), []);
  const { track } = useAnalytics();
  const { value: anonymousUserId } = useAnonymousUser();
  const { push } = useRouter();
  const { estimate } = useEstimations();

  const handleOnSuccess = async (credentials: CredentialResponse) => {
    setGoogleIsLoading?.(true);
    await signInGoogle({
      data: {
        google_access_token: credentials.credential!,
        client_id: apiClientId!,
        client_secret: apiClientSecret!,
        grant_type: "google",
      },
    });
    if (isRAF) {
      const data = parseValuesForEstimation(rafFormValues);
      const res = await estimate({
        data,
      });

      const user = res.user;
      const isNewAccount = isAfter(
        add(user?.passwordCreatedAt ?? "", 2, "minute"),
        new Date(),
      );

      if (user && isNewAccount) {
        track("new_registration", {
          cookieID: anonymousUserId,
          page: "public_flow",
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          departure: rafFormValues?.legs[0]!.departureAirport.value.airportCode,
          destination:
            rafFormValues?.legs![rafFormValues!.legs!.length! - 1]!
              .destinationAirport.value.airportCode,
          departure_datetime: rafFormValues?.legs[0]!.departureAt,
          destination_datetime:
            rafFormValues?.legs[rafFormValues.legs.length - 1]!.returnAt,
          number_of_seat: rafFormValues?.numberOfSeats.passengers,
        });
      }

      push("/?prefillForm=true");
      setGoogleIsLoading?.(false);
    }
    setGoogleIsLoading?.(false);
  };

  return (
    <StyledButton>
      <GoogleLogin
        size="large"
        theme="filled_blue"
        text="continue_with"
        locale="en_US"
        onError={() => {
          pushFlashMessage({
            type: FlashMessageTypes.Error,
            text: "Failed to login with Google",
          });
        }}
        onSuccess={handleOnSuccess}
      />
    </StyledButton>
  );
};
