/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from "react";

import styled from "@emotion/styled";
import styledMap from "styled-map";

import { Separator } from "@fllite/ui/components";

const TabsContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

interface TabProps {
  active?: boolean;
}

const tabColor = styledMap`
  default: ${({ theme }: any) => (theme as any).colors.grayDarker};
  active: ${({ theme }: any) => (theme as any).colors.primary};
`;

const tabFontWeight = styledMap`
  default: 500;
  active: 600;
`;

export const Tab = styled("button")<TabProps>`
  font-size: 20px;
  font-weight: ${tabFontWeight};
  color: ${tabColor};
  transition: color ${({ theme }) => (theme as any).transition.baseTransition};
  padding: 14px 15px;
  background: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;

  ${({ active, theme }) =>
    active && `border-bottom: 3px solid ${(theme as any).colors.primary};`}

  &:hover {
    color: ${({ theme, active }) =>
      active
        ? (theme as any).colors.primary
        : (theme as any).colors.primaryDark};
  }
`;

const Container = styled("div")`
  margin: 0 -15px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin: 0;
  }
`;

interface TabsProps {
  children: ReactNode;
}

export const Tabs: FC<TabsProps> = ({ children }) => {
  return (
    <Container>
      <TabsContainer>{children}</TabsContainer>
      <Separator />
    </Container>
  );
};
