import { FC, ReactNode } from "react";

import styled from "@emotion/styled";

import { Separator } from "@fllite/ui/components";
import { Text } from "@fllite/ui/components";

const Container = styled("div")`
  display: flex;
  align-items: center;
  margin: 23px 0;
`;

const Caption = styled("div")`
  padding: 0 10px;
  flex: 1 0 auto;
`;

interface SectionCaptionProps {
  children: ReactNode;
}

export const SectionCaption: FC<SectionCaptionProps> = ({ children }) => (
  <Container>
    <Separator />
    <Caption>
      <Text small grayLight>
        {children}
      </Text>
    </Caption>
    <Separator />
  </Container>
);
